import { VariableConfig } from './types';

export const defaultVariables: { [key: string]: VariableConfig } = {
    temperature: {
        name: 'Temperature (°C)',
        key: 'temperature',
        min: -40,
        max: 85,
        initialValue: 21,
        enabled: true,
        noDataByDefault: false,
        tooltip: 'Optimal indoor temperature is between 18°C and 24°C.',
        gradient: [
            { value: -40, color: 'blue' },
            { value: 0, color: 'blue' },
            { value: 18, color: 'yellow' },
            { value: 21, color: 'green' },
            { value: 24, color: 'yellow' },
            { value: 85, color: 'red' },
          ],
      },
      humidity: {
        name: 'Humidity (%)',
        key: 'humidity',
        min: 0,
        max: 100,
        initialValue: 40,
        enabled: true,
        noDataByDefault: false,
        gradient: [
            { value: 0, color: 'red' },
            { value: 30, color: 'yellow' },
            { value: 40, color: 'green' },
            { value: 50, color: 'yellow' },
            { value: 100, color: 'red' },
          ],
      },
        co2: {
            name: 'CO2 (ppm)',
            key: 'co2',
            min: 0,
            max: 4000,
            initialValue: 400,
            enabled: true,
            noDataByDefault: false,
            gradient: [
                { value: 0, color: 'green' },
                { value: 400, color: 'green' },
                { value: 600, color: 'lightgreen' },
                { value: 1000, color: 'yellow' },
                { value: 4000, color: 'red' },
              ],
        },
        voc: {
            name: 'VOC (index)',
            key: 'voc',
            min: 0,
            max: 500,
            initialValue: 50,
            enabled: true,
            noDataByDefault: false,
            gradient: [
                { value: 0, color: 'green' },
                { value: 125, color: 'green' },
                { value: 200, color: 'lightgreen' },
                { value: 500, color: 'red' },
              ],
        },
        nox: {
            name: 'NOx (index)',
            key: 'nox',
            min: 0,
            max: 500,
            initialValue: 115,
            enabled: true,
            noDataByDefault: false,
            gradient: [
                { value: 0, color: 'green' },
                { value: 125, color: 'green' },
                { value: 200, color: 'lightgreen' },
                { value: 500, color: 'red' },
              ],
        },
        pm25: {
            name: 'PM2.5 (µg/m³)',
            key: 'pm25',
            min: 0,
            max: 200,
            initialValue: 5,
            enabled: true,
            noDataByDefault: false,
            gradient: [
                { value: 0, color: 'green' },
                { value: 12, color: 'green' },
                { value: 35, color: 'lightgreen' },
                { value: 100, color: 'yellow' },
                { value: 200, color: 'red' },
              ],
        },

        luminosity: {
            name: 'Luminosity (lux)',
            key: 'luminosity',
            min: 0,
            max: 100000,
            initialValue: 500,
            enabled: false,
            noDataByDefault: false,
            gradient: [
                { value: 0, color: 'red' },
                { value: 100, color: 'red' },
                { value: 500, color: 'yellow' },
                { value: 1000, color: 'green' },
                { value: 100000, color: 'green' },
              ],
        },
        soundLevel: {
            name: 'Sound Level (dBA)',
            key: 'soundLevel',
            min: 0,
            max: 120,
            initialValue: 40,
            enabled: false,
            noDataByDefault: false,
            gradient: [
                { value: 0, color: 'green' },
                { value: 40, color: 'green' },
                { value: 70, color: 'yellow' },
                { value: 85, color: 'red' },
                { value: 120, color: 'red' },
              ],
        },
    };