
import { AirQualityData, AirQualityResult, CalculationMethod } from './types';

export const resetIndex: CalculationMethod = {
  name: 'RESET™ Air STANDARD for Commercial Interiors v2.0',
  description: `RESET™ Air is a performance-based building standard. In order for a project to achieve RESET™ Air Certification 
  for Commercial Interiors, indoor air quality parameters, as tracked through continuous monitoring and calculated
  into a daily average according to hours of occupancy, must be maintained within the limits based on industry best-practices and international standards.`,
  source: 'https://www.reset.build/standard/air#air_download',
  citation: '[RESET™ Air STANDARD for Commercial Interiors v2.0, 2018, 2.2.2 Indoor Air Quality Performance Targets]',
  variableOverrides: {
    temperature: {
      tooltip: 'Temperature does not affect the RESET™ Air Standard level, but measurement is required for sensor calibration.',
      gradient: [
        { value: -40, color: 'gray' },
        { value: 85, color: 'gray' },
      ],
    },
    humidity: {
      tooltip: 'Humidity does not affect the RESET™ Air Standard level, but measurement is required for sensor calibration.',
      gradient: [
        { value: 0, color: 'gray' },
        { value: 100, color: 'gray' },
      ],
    },
    pm25: {
      tooltip: 'PM2.5 levels must be maintained below 35 μg/m³ and should be maintained below 12 μg/m³.',
    },
    voc: {
      tooltip: 'VOC index reading of sensor is converted to TVOC equivalent according to sensor manufacturer guidelines.',
      gradient: [
        { value: 0, color: 'green' },
        { value: 125, color: 'lightgreen' },
        { value: 176, color: 'yellow' },
        { value: 210, color: 'red' },
        { value: 500, color: 'red' },
      ],
    },
    nox: {
      enabled: false,
    },
    co2: {
      tooltip: 'CO2 levels must be maintained below 1000 ppm and should be maintained below 600 ppm.',
      gradient: [
        { value: 0, color: 'green' },
        { value: 400, color: 'green' },
        { value: 600, color: 'yellow' },
        { value: 800, color: 'orange' },
        { value: 1000, color: 'red' },
        { value: 4000, color: 'crimson' },
      ],
    },
    co:
    {
      name: 'CO (mg/m³)',
      key: 'co',
      min: 0,
      max: 100,
      initialValue: 0,
      enabled: true,
      tooltip: 'CO levels must be maintained below 9 mg/m³. CO measurement is required only in spaces with combustion.',
      noDataByDefault: true,
      gradient: [
        { value: 0, color: 'green' },
        { value: 5, color: 'yellow' },
        { value: 9, color: 'red' },
        { value: 100, color: 'red' },
      ],
    },
    luminosity: { 
      enabled: false,
    },
    soundLevel: {
      enabled: false,
    },
  },
  calculate: (data: AirQualityData): AirQualityResult => {
  const AQG = Math.min(scorePpm(data.pm25), scoreVoc(data.voc), scoreCo(data.co), scoreCo2(data.co2), scoreTemperature(data.temperature), scoreHumidity(data.humidity));

  return {
    index: AQG,
    result: getResultFromAQG(AQG),
    color: getColorFromAQG(AQG),
    description: getDescriptionFromAQG(AQG),
    };
 }
}
  
  function scorePpm(ppm: number | undefined): number {
    // Missing value not allowed
    if (ppm === undefined) return 0;
    if (ppm > 35) return 0;
    if (ppm > 12) return 1;
    return 2;
  }

  function scoreCo(co: number | undefined): number {
    // Missing value allowed on spaces without combustion
    if (co === undefined) return 2;
    if (co > 9) return 0;
    // NOX does not have target level 1
    return 2;
  }

  function scoreVoc(voc: number | undefined): number {
    // Missing value not allowed
    if (voc === undefined) return 0;
    // VOC is converted to TVOC equivalent according to sensor manufacturer guidelines
    const TVOC =(Math.log(501 - voc) - 6.24) * (-878.53)
    if (TVOC > 500) return 0;
    if (TVOC > 400) return 1;
    return 2;
  }

  function scoreCo2(co2: number | undefined): number {
    // Missing value not allowed
    if (co2 === undefined) return 0;
    if (co2 > 1000) return 0;
    if (co2 > 600) return 1;
    return 2;
  }

  function scoreTemperature(Temperature: number | undefined): number {
    // Missing value not allowed
    if (Temperature === undefined) return 0;
    return 2;
  }

  function scoreHumidity(Humidity: number | undefined): number {
    // Missing value not allowed
    if (Humidity === undefined) return 0;
    return 2;
  }
  
  function getColorFromAQG(AQG: number): string {
    if (AQG === 0) return 'red';
    if (AQG === 1) return 'orange';
    if (AQG === 2) return 'green';
    return 'black';
  }
  
  function getDescriptionFromAQG(AQG: number): string {
    if (AQG === 0) return 'RESET Standard not met - unhealty air';
    if (AQG === 1) return 'RESET Standard acceptable level met - moderate air quality';
    if (AQG === 2) return 'RESET Standard high-performance level met - good air quality';
    return 'No data';
  }

  function getResultFromAQG(AQG: number): string {
    if (AQG === 0) return 'Standard not met';
    if (AQG === 1) return 'Acceptable';
    if (AQG === 2) return 'High Performance';
    return 'No data';
  }