
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import {
  AirQualityData,
  AirQualityResult,
  CalculationMethod, calculationMethods,
  getVariablesForMethod,
  VariableConfig
} from '../library';

const inputsDiv = document.getElementById('inputs') as HTMLDivElement;
const outputDiv = document.getElementById('output') as HTMLDivElement;

const methodSelector = document.getElementById('calculation-method') as HTMLSelectElement;

calculationMethods.forEach((method, index) => {
  const option = document.createElement('option');
  option.value = index.toString();
  option.textContent = method.name;
  methodSelector.appendChild(option);
});

// Set the default selected method
let selectedMethod: CalculationMethod = calculationMethods[0];


// Handle method selection
methodSelector.addEventListener('change', () => {
  const selectedIndex = parseInt(methodSelector.value, 10);
  selectedMethod = calculationMethods[selectedIndex];
  variables = getVariablesForMethod(selectedMethod.variableOverrides);

  // Update variablesMap
  variablesMap = {};
  variables.forEach((variable) => {
    variablesMap[variable.key] = variable;
  });

  updateMethodDescription();
  recreateSliders();
  updateResults();
});

const methodDescriptionDiv = document.createElement('div');
methodDescriptionDiv.id = 'method-description';
methodDescriptionDiv.style.marginBottom = '20px';

// Insert the description div after the method selector
const methodSelectorDiv = document.getElementById('method-selector');
methodSelectorDiv?.after(methodDescriptionDiv);

function updateMethodDescription() {
  methodDescriptionDiv.innerHTML = `${selectedMethod.description} <small><a href=${selectedMethod.source}>${selectedMethod.citation}</a></small>`;
}

function recreateSliders() {
  // Clear existing sliders
  inputsDiv.innerHTML = '';

  // Do not reset airQualityData; preserve user-configured values

  // Create sliders for the new method
  variables.forEach((variable) => {
    createSlider(variable);
  });

  // Update results with the preserved data
  updateResults();
}


// Initialize the description
updateMethodDescription();


const airQualityData: AirQualityData = {};
let variables: VariableConfig[] = getVariablesForMethod(selectedMethod.variableOverrides);
let variablesMap: { [key: string]: VariableConfig } = {};
variables.forEach((variable) => {
  variablesMap[variable.key] = variable;
});

variables.forEach((variable) => {
  createSlider(variable);
});

function createSlider(config: VariableConfig) {
  // Get the existing value from airQualityData or use the initial value
  const existingValue = airQualityData[config.key as keyof AirQualityData] ?? config.initialValue;

  // Create elements
  const sliderContainer = document.createElement('div');
  sliderContainer.className = 'slider-container';

  // Create the label element
  const label = document.createElement('label');
  label.className = 'variable-label'; // Add a class for styling

  // Create a container for the label text and icon
  const labelContent = document.createElement('span');
  labelContent.className = 'label-content';
  labelContent.textContent = config.name;

  // Append the label content to the label
  label.appendChild(labelContent);

  // Check if a tooltip is available
  if (config.tooltip) {
    // Create the tooltip icon
    const tooltipIcon = document.createElement('span');
    tooltipIcon.className = 'tooltip-icon';
    tooltipIcon.setAttribute('tabindex', '0'); // Make focusable for accessibility
    tooltipIcon.setAttribute('aria-label', 'More information');
    tooltipIcon.setAttribute('role', 'button');

    // Add the SVG icon
    tooltipIcon.innerHTML = `
      <svg width="16" height="16" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
        <circle cx="12" cy="12" r="12" fill="#888"/>
        <text x="12" y="16" text-anchor="middle" font-size="16" fill="#fff" font-family="Arial, sans-serif">?</text>
      </svg>
    `;

    // Append the tooltip icon to the label
    label.appendChild(tooltipIcon);

    // Initialize tooltip using Tippy.js or your chosen method
    tippy(tooltipIcon, {
      content: config.tooltip,
      placement: 'top',
      arrow: true,
      delay: [500, 0],
      trigger: 'mouseenter focus', // Show tooltip on hover and focus
    });
  }

  const valueDisplay = document.createElement('span');
  valueDisplay.className = 'value-display';
  valueDisplay.textContent = config.initialValue.toString();

  // Create 'No Data' checkbox
  const noDataCheckbox = document.createElement('input');
  noDataCheckbox.type = 'checkbox';
  noDataCheckbox.id = `nodata-${config.key}`;
  noDataCheckbox.style.marginLeft = '10px';
  noDataCheckbox.checked = config.noDataByDefault === true;

  const noDataLabel = document.createElement('label');
  noDataLabel.htmlFor = noDataCheckbox.id;
  noDataLabel.textContent = 'No Data';

  // Create a status indicator element
  const statusIndicator = document.createElement('span');
  statusIndicator.className = 'status-indicator';
  statusIndicator.style.marginLeft = '10px';

  // Create slider element
  const sliderElement = document.createElement('div');
  sliderElement.className = 'slider';

  // Append elements
  label.prepend(valueDisplay, ' ');
  label.appendChild(noDataCheckbox);
  label.appendChild(noDataLabel);
  label.appendChild(statusIndicator);
  sliderContainer.appendChild(label);
  sliderContainer.appendChild(sliderElement);
  inputsDiv.appendChild(sliderContainer);

  // Initialize noUiSlider
  noUiSlider.create(sliderElement, {
    start: existingValue,
    connect: [true, false],
    range: {
      min: config.min,
      max: config.max,
    },
    step: 0.1,
    tooltips: false,
  });

  // Handle slider updates
  sliderElement.noUiSlider?.on('update', (values, handle) => {
    const value = parseFloat(values[handle]);
    valueDisplay.textContent = value.toFixed(1);

    const key = config.key;
    if (!noDataCheckbox.checked && config.enabled !== false) {
      airQualityData[key as keyof AirQualityData] = value;
    } else {
      delete airQualityData[key as keyof AirQualityData];
    }

    updateResults();
  });

  // Handle 'No Data' checkbox change
  noDataCheckbox.addEventListener('change', () => {
    handleNoDataChange(noDataCheckbox, config, sliderElement);
  });

    // Store the existing value in airQualityData
    airQualityData[config.key as keyof AirQualityData] = existingValue;

  // Initialize the slider state
  if (config.enabled === false) {
    // Variable is not applicable
    disableSlider(sliderElement);
    statusIndicator.textContent = 'Not Applicable';
    noDataLabel.style.display = 'none';
    noDataCheckbox.style.display = 'none';
    delete airQualityData[config.key as keyof AirQualityData];
  } else if (noDataCheckbox.checked) {
    // No Data is checked
    disableSlider(sliderElement);
    sliderElement.classList.add('disabled-slider');
    delete airQualityData[config.key as keyof AirQualityData];
  } else {
    // Variable is enabled and has data
    enableSlider(sliderElement);
    statusIndicator.textContent = '';
  }


  // Customize the slider's background gradient
  customizeSliderGradient(sliderElement, config);
}


function handleNoDataChange(
  noDataCheckbox: HTMLInputElement,
  config: VariableConfig,
  sliderElement: noUiSlider.Instance
) {
  if (noDataCheckbox.checked) {
    // Simulate missing data
    delete airQualityData[config.key as keyof AirQualityData];
    disableSlider(sliderElement);
  } else {
    // Restore data if the variable is enabled
    if (config.enabled) {
      enableSlider(sliderElement);
      const value = sliderElement.noUiSlider?.get();
      if (value !== undefined) {
        airQualityData[config.key as keyof AirQualityData] = parseFloat(value as string);
      }
    }
  }
  updateResults();
}

function disableSlider(sliderElement: noUiSlider.Instance) {
  sliderElement.setAttribute('disabled', true);
  // Optionally, style the slider to appear gray
  sliderElement.classList.add('disabled-slider');
}

function enableSlider(sliderElement: noUiSlider.Instance) {
  sliderElement.removeAttribute('disabled');
  sliderElement.classList.remove('disabled-slider');
}


function customizeSliderGradient(sliderElement: noUiSlider.Instance, config: VariableConfig) {
  const base = sliderElement.querySelector('.noUi-base') as HTMLElement;

  if (!config.gradient || config.gradient.length < 2) {
    base.style.background = '';
    return;
  }

  const gradientStrings: string[] = [];

  const range = config.max - config.min;

  if(config.enabled) {
    config.gradient.forEach((stop, index) => {
      // Clamp the stop value to min and max
      const clampedValue = Math.min(Math.max(stop.value, config.min), config.max);
      const percentage = ((clampedValue - config.min) / range) * 100;
      gradientStrings.push(`${stop.color} ${percentage}%`);
    });
  } else {
    gradientStrings.push('gray 0%');
    gradientStrings.push('gray 100%');
  }

  const gradientStyle = `linear-gradient(to right, ${gradientStrings.join(', ')})`;

  base.style.background = gradientStyle;

  // Ensure the .noUi-connect element is transparent
  const connect = sliderElement.querySelector('.noUi-connect') as HTMLElement;
  if (connect) {
    connect.style.background = 'transparent';
  }
}


  

  function updateResults() {
    const result: AirQualityResult = selectedMethod.calculate(airQualityData);
    displayResult(result);
  }

function displayResult(result: AirQualityResult) {
  outputDiv.innerHTML = `
    <h2>Results</h2>
    <div id="result-index">${selectedMethod.name}: <span style="color: ${result.color};">${result.result}</span></div>
    <div id="result-description">${result.description}</div>
  `;
}