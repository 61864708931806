import { AirQualityData, AirQualityResult, CalculationMethod } from './types';

export const moldIndex: CalculationMethod = {
  name: 'Mold Index',
  description: 'Calculates the Mold Risk Index based on temperature and humidity.',
  source: 'https://github.com/Strixx76/mold_risk_index',
  citation: 'Strixx76. Mold Risk Index for Home Assistant.',
  variableOverrides: {
    temperature: {
      initialValue: 21,
      tooltip: 'Mold cannot grow below 0°C or above 50°C.',
      gradient: [
        { value: -40, color: 'green' },
        { value: 0, color: 'yellow' },
        { value: 25, color: 'red' },
        { value: 50, color: 'yellow' },
        { value: 85, color: 'green' },
      ],
    },
    humidity: {
      initialValue: 40,
      tooltip: 'High humidity (above 70%) increases the risk of mold growth.',
      gradient: [
        { value: 0, color: 'green' },
        { value: 60, color: 'lightgreen' },
        { value: 72, color: 'orange' },
        { value: 82, color: 'orangered' },
        { value: 86, color: 'red' },
        { value: 100, color: 'Crimson' },
      ],
    },
    co2: {
        enabled: false,
    },
    voc: {
        enabled: false,
    },
    nox: {
        enabled: false,
    },
    pm25: {
        enabled: false,
    },
    luminosity: { 
        enabled: false,
      },
      soundLevel: {
        enabled: false,
      },
  },
  calculate: (data: AirQualityData): AirQualityResult => {
    let index = 0;
    let result = "";
    let color = "gray";
    let description = "This index requires temperature and humidity data.";
    if(data.temperature !== undefined && data.humidity !== undefined) {
        const limit_1 = calc_limit_1(data.temperature, data.humidity);
        const limit_2 = calc_limit_2(data.temperature, data.humidity);
        const limit_3 = calc_limit_3(data.temperature, data.humidity);
        if (data.humidity >= limit_3) {
            index = 1;
            result = "Level 3";
            color = "Crimson";
            description = "Great risk of mold growth";
        } else if (data.humidity >= limit_2) {
            index = 2;
            result = "Level 2";
            color = "orangered";
            description = "Enchanced risk of mold growth";
        } else if (data.humidity >= limit_1) {
            index = 3;
            result = "Level 1";
            color = "orange";
            description = "Risk of mold growth";
        } else if (data.temperature < 0) {
            index = 100;
            result = "Level 0"
            color = "green";
            description = "Too cold for mold";
        } else if (data.temperature > 50) {
            index = 100;
            result = "Level 0"
            color = "Tomato";
            description = "Too hot for mold";
        }
        else {
            index = 100;
            result = "Level 0"
            color = "Green";
            description = "Too dry for mold";
        }
    }
    return {
      index: index,
      result: result,
      color: color,
      description: description
    };
  },
};


function calc_limit_1(temperature: number, humidity: number): number {
        if (temperature >= 0 && temperature <= 50) {
            const limit = Math.round(20 * Math.exp( -temperature * 0.15 ) + 73);
            return Math.max(Math.min(100, limit), 72);
        }
        else {
            return 100;
        }
}

function calc_limit_2(temperature: number, humidity: number): number {
    if (temperature >= 0 && temperature <= 50) {
        const limit = Math.round(17 * Math.exp( -temperature * 0.11 ) + 80);
        return Math.max(Math.min(100, limit), 79);
    }
    else {
        return 100;
    }
}

function calc_limit_3(temperature: number, humidity: number): number {
    if (temperature >= 0 && temperature <= 50) {
        const limit = Math.round(15 * Math.exp( -temperature * 0.10 ) + 85);
        return Math.max(Math.min(100, limit), 84);
    }
    else {
        return 100;
    }
}
