
import { AirQualityData, AirQualityResult, CalculationMethod } from './types';

export const whoIndex: CalculationMethod = {
  name: 'WHO Air Quality Target - 24 Hour average level',
  description: 'Checks if current environment meets the Air Quality Quidelines based on PM2.5, VOC, NOx, CO, SO2 and O3. Compares to Interim Target and AQG levels, lowest match is returned.',
  source: 'https://iris.who.int/bitstream/handle/10665/345329/9789240034228-eng.pdf',
  citation: '[WHO global air quality guidelines: particulate matter (PM2.5 and PM10), ozone, nitrogen dioxide, sulfur dioxide and carbon monoxide. Geneva: World Health Organization; 2021. Table 3.24, 24-hour averages, p.135]',
  variableOverrides: {
    temperature: {
      tooltip: '',
      enabled: false,
    },
    humidity: {
      enabled: false,
    },
    pm25: {
      enabled: true,
      gradient: [
        { value: 0, color: 'green' },
        { value: 15, color: 'lightgreen' },
        { value: 25, color: 'yellow' },
        { value: 37.5, color: 'orange' },
        { value: 50, color: 'darkorange' },
        { value: 75, color: 'red' },
        { value: 500, color: 'crimson' },
      ],
    },
    voc: {
      enabled: false,
    },
    nox: {
      enabled: false,
    },
    o3: {
      name: 'O3 (µg/m³)',
      key: 'o3',
      min: 0,
      max: 500,
      initialValue: 50,
      enabled: true,
      noDataByDefault: false,
      gradient: [
        { value: 0, color: 'green' },
        { value: 120, color: 'orange' },
        { value: 160, color: 'red' },
        { value: 500, color: 'Crimson' },
      ],
  },
  so2: {
      name: 'SO2 (µg/m³)',
      key: 'so2',
      min: 0,
      max: 500,
      initialValue: 50,
      enabled: true,
      noDataByDefault: false,
      gradient: [
        { value: 0, color: 'green' },
        { value: 40, color: 'orange' },
        { value: 50, color: 'red' },
        { value: 100, color: 'Crimson' },
      ],
  },
    co2: {
      enabled: false,
      gradient: [
        { value: 0, color: 'gray' },
        { value: 500, color: 'gray' },
      ],
    },
    luminosity: { 
      enabled: false,
    },
    soundLevel: {
      enabled: false,
    },
  },
  calculate: (data: AirQualityData): AirQualityResult => {
  const AQG = Math.min(scorePpm(data.pm25), scoreNox(data.nox), scoreCo(data.co), scoreSo(data.so2), scoreO3(data.o3));

  return {
    index: AQG,
    result: getResultFromAQG(AQG),
    color: getColorFromAQG(AQG),
    description: getDescriptionFromAQG(AQG),
    };
 }
}
  
  function scorePpm(ppm: number | undefined): number {
    if (ppm === undefined) return 5;
    if (ppm > 75) return 0;
    if (ppm > 50) return 1;
    if (ppm > 37.5) return 2;
    if (ppm > 25) return 3;
    if (ppm > 15) return 4;
    return 5;
  }

  function scoreO3(o3: number | undefined): number {
    if (o3 === undefined) return 5;
    if (o3 > 160) return 0;
    if (o3 > 120) return 1;
    if (o3 > 100) return 2;
    // O3 does not have target levels 3, 4
    return 5;
  }

  function scoreNox(nox: number | undefined): number {
    if (nox === undefined) return 5;
    if (nox > 120) return 0;
    if (nox > 50) return 1;
    // NOX does not have target levels 2, 3
    if (nox > 25) return 4;
    return 5;
  }

  function scoreSo(so2: number | undefined): number {
    if (so2 === undefined) return 5;
    if (so2 > 125) return 0;
    if (so2 > 50) return 1;
    if (so2 > 40) return 2;
    // SO2 does not have target levels 3, 4
    return 5;
  }

  function scoreCo(co: number | undefined): number {
    if (co === undefined) return 5;
    if (co > 7) return 0;
    if (co > 4) return 1;
    // NOX does not have target levels 2, 3, 4
    return 5;
  }
  
  function getColorFromAQG(AQG: number): string {
    if (AQG === 0) return 'red';
    if (AQG === 1) return 'orangered';
    if (AQG === 2) return 'orange';
    if (AQG === 3) return 'khaki';
    if (AQG === 4) return 'lightgreen';
    if (AQG === 5) return 'green';
    return 'black';
  }
  
  function getDescriptionFromAQG(AQG: number): string {
    if (AQG === 0) return 'Air Quality Guidelines not met - unhealty air';
    if (AQG === 1) return 'Air Quality Guideline interim target 1 met - poor air quality';
    if (AQG === 2) return 'Air Quality Guideline interim target 2 met - moderate air quality';
    if (AQG === 3) return 'Air Quality Guideline interim target 3 met - good air quality';
    if (AQG === 4) return 'Air Quality Guideline interim target 4 met - very good air quality';
    if (AQG === 5) return 'Air Quality Guidelines met - excellent air quality';
    return 'No data';
  }

  function getResultFromAQG(AQG: number): string {
    if (AQG === 0) return 'Target not met';
    if (AQG === 1) return 'Level 1';
    if (AQG === 2) return 'Level 2';
    if (AQG === 3) return 'Level 3';
    if (AQG === 4) return 'Level 4';
    if (AQG === 5) return 'Target met';
    return 'No data';
  }
