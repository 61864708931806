export interface AirQualityData {
    temperature?: number;
    humidity?: number;
    co?: number;
    co2?: number;
    voc?: number;
    nox?: number;
    so2?: number;
    o3?: number;
    pm25?: number;
    soundLevel?: number;
    luminosity?: number;
    // Additional variables can be added here
  }
  
  export interface AirQualityResult {
    index: number; // Value from 0 to 100
    result: string; // Result string. Number, or text.
    color: string; // 'green', 'yellow', 'red', etc.
    description: string; // 'Good', 'Moderate', 'Unhealthy', etc.
  }

  export interface GradientStop {
    value: number;   // The value at which the color stop occurs
    color: string;   // The color at this stop
  }

  export interface VariableConfig {
    name: string;
    key: string;
    enabled: boolean;
    min: number;
    max: number;
    gradient: GradientStop[];
    initialValue: number;
    noDataByDefault: boolean
    tooltip?: string;
  }

  export interface VariableConfigOverride {
    name?: string;
    key?: string;
    enabled?: boolean;
    min?: number;
    max?: number;
    initialValue?: number;
    noDataByDefault?: boolean
    tooltip?: string;
    gradient?: GradientStop[];
  }
  
  export interface CalculationMethod {
    name: string; // Display name
    description: string; // Brief description
    source: string; // Source of the method (e.g., link to a scientific paper)
    citation: string; // Citation for the method
    calculate(data: AirQualityData): AirQualityResult;
    variableOverrides?: Partial<{ [key: string]: VariableConfigOverride }>; // Override default variable settings
  }

  