
import { AirQualityData, AirQualityResult, CalculationMethod } from './types';

export const ruuviAQIndex: CalculationMethod = {
  name: 'Ruuvi Air Quality Index',
  description: `Ruuvi Air Quality Index considers difference of air quality variables to ideal conditions to for a composite score 0-100, where 0 is worst and 100 is best. If data is not available, it will not affect the score.`,
  source: '',
  citation: '',
  variableOverrides: {
    temperature: {
      tooltip: 'Temperature is ideally 18-24°C indoors.',
      gradient: [
        { value: -40, color: 'royalblue' },
        { value: 12, color: 'cyan' },
        { value: 15, color: 'lightcyan' },
        { value: 18, color: 'lightgreen' },
        { value: 21, color: 'green' },
        { value: 24, color: 'lightgreen' },
        { value: 27, color: 'orange' },
        { value: 30, color: 'orangered' },
        { value: 85, color: 'crimson' },
      ],
    },
    humidity: {
      tooltip: 'Humidity is ideally 40-60 % indoors.',
      gradient: [
        { value: 0, color: 'crimson' },
        { value: 20, color: 'orangered' },
        { value: 30, color: 'orange' },
        { value: 40, color: 'lightgreen' },
        { value: 50, color: 'green' },
        { value: 60, color: 'lightgreen' },
        { value: 70, color: 'orange' },
        { value: 80, color: 'orangered' },
        { value: 100, color: 'crimson' },
      ],
    },
    pm25: {
      tooltip: 'PM2.5 levels must be maintained below 35 μg/m³ and should be maintained below 12 μg/m³.',
      gradient: [
        { value: 0, color: 'green' },
        { value: 12, color: 'lightgreen' },
        { value: 35, color: 'orange' },
        { value: 100, color: 'orangered' },
        { value: 300, color: 'crimson' },
      ],
    },
    voc: {
      tooltip: 'VOC index reading of sensor is converted to TVOC equivalent according to sensor manufacturer guidelines.',
      gradient: [
        { value: 0, color: 'green' },
        { value: 125, color: 'lightgreen' },
        { value: 176, color: 'orange' },
        { value: 210, color: 'orangered' },
        { value: 500, color: 'crimson' },
      ],
    },
    nox: {
      tooltip: 'NOx index should be maintained below 200.',
      gradient: [
        { value: 0, color: 'green' },
        { value: 200, color: 'lightgreen' },
        { value: 300, color: 'orange' },
        { value: 400, color: 'red' },
        { value: 500, color: 'crimson' },
      ],
    },
    co2: {
      tooltip: 'CO2 levels must be maintained below 1000 ppm and should be maintained below 600 ppm.',
      min: 400,
      gradient: [
        { value: 400, color: 'green' },
        { value: 600, color: 'lightgreen' },
        { value: 1000, color: 'orange' },
        { value: 2000, color: 'red' },
        { value: 4000, color: 'crimson' },
      ],
    },
    luminosity: { 
      enabled: false,
    },
    soundLevel: {
      enabled: false,
    },
  },
  calculate: (data: AirQualityData): AirQualityResult => {
  // Geometric distance to ideal conditions
  const distances = [];
  if(data.temperature !== undefined) {
      console.log(`Temperature distance: ${scoreTemperature(data.temperature)}`);
      distances.push(scoreTemperature(data.temperature));
  }
  if(data.humidity !== undefined) {
      console.log(`Temperature distance: ${scoreHumidity(data.humidity)}`);
      distances.push(scoreHumidity(data.humidity));
  }
  if(data.pm25 !== undefined) {
      console.log(`PM2.5 distance: ${scorePpm(data.pm25)}`);
      distances.push(scorePpm(data.pm25));
  }
  if(data.voc !== undefined) {
      console.log(`VOC distance: ${scoreVoc(data.voc)}`);
      distances.push(scoreVoc(data.voc));
  }
  if(data.co2 !== undefined) {
      console.log(`CO2 distance: ${scoreCo2(data.co2)}`);
      distances.push(scoreCo2(data.co2));
  }
  if(data.nox !== undefined) {
      console.log(`NOx distance: ${scoreNox(data.nox)}`);
      distances.push(scoreNox(data.nox));
  }
  const distance = Math.sqrt(distances.reduce((acc, val) => acc + val * val, 0) / distances.length);
  console.log(`Distance: ${distance}`);
  const index = Math.max(0, 100 - distance);

  return {
    index: index,
    result: getResultFromIndex(index),
    color: getColorFromIndex(index),
    description: getDescriptionFromIndex(index),
    };
 }
}
  
  function scorePpm(ppm: number): number {
    return Math.max(0, (ppm - 12) * 2);
  }

  function scoreVoc(voc: number): number {
    return Math.max(0, (voc - 200));
  }

  function scoreNox(nox: number): number {
    return Math.max(0, (nox - 200));
  }

  function scoreCo2(co2: number): number {
    return Math.max(0, (co2 - 600)/10);
  }

  function scoreTemperature(Temperature: number): number {
    return Math.max(Math.max(0, (Temperature - 24) * 10), Math.max(0, 18 - Temperature) * 10);
  }

  function scoreHumidity(Humidity: number): number {
    return Math.max(Math.max(0, (Humidity - 60) * 5), Math.max(0, (40 - Humidity) * 5));
  }
  
  function getColorFromIndex(index: number): string {
    if (index > 80) return 'green';
    if (index > 60) return 'lightgreen';
    if (index > 40) return 'orange';
    if (index > 20) return 'orangered';
    return 'crimson';
  }
  
  function getDescriptionFromIndex(index: number): string {
    if (index > 80) return 'Excellent';
    if (index > 60) return 'Good';
    if (index > 40) return 'Moderate';
    if (index > 20) return 'Poor';
    return 'Unhealty';
  }

  function getResultFromIndex(index: number): string {
    return index.toFixed(0);
  }