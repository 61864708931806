import { VariableConfig, VariableConfigOverride } from './types';
import { defaultVariables } from './defaultVariables';

export function getVariablesForMethod(
  variableOverrides?: Partial<{ [key: string]: Partial<VariableConfigOverride> }>
): VariableConfig[] {
  const variables: VariableConfig[] = [];

  for (const key in defaultVariables) {
    if (defaultVariables.hasOwnProperty(key)) {
      const defaultConfig = defaultVariables[key];
      const overrideConfig = variableOverrides?.[key] || {};

      // Merge defaultConfig with overrideConfig
      const mergedConfig: VariableConfig = {
        ...defaultConfig,
        ...overrideConfig,
      };

      variables.push(mergedConfig);
    }
  }

  // Add any new variables specified in overrides that aren't in defaults
  if (variableOverrides) {
    for (const key in variableOverrides) {
      if (
        variableOverrides.hasOwnProperty(key) &&
        !defaultVariables.hasOwnProperty(key)
      ) {
        const overrideConfig = variableOverrides[key];
        if (overrideConfig) {
          variables.push({
            name: overrideConfig.name || key,
            key: key,
            min: overrideConfig.min || 0,
            max: overrideConfig.max || 100,
            initialValue: overrideConfig.initialValue || 0,
            enabled: overrideConfig.enabled || false,
            noDataByDefault: overrideConfig.noDataByDefault || true,
            tooltip: overrideConfig.tooltip || undefined,
            gradient: overrideConfig.gradient || [{ value: 0, color: 'green' }],
          });
        }
      }
    }
  }

  return variables;
}