import { CalculationMethod } from './types';
import { eaqIndex } from './eaqi';
import { moldIndex } from './moldIndex';
import { resetIndex } from './reset';
import { ruuviAQIndex } from './ruuvi-aqi';
import { whoIndex } from './whoAirq-24h';
export * from './variableUtils';
export * from './types';
export { eaqIndex } from './eaqi';
export { resetIndex } from './reset';
export { ruuviAQIndex } from './ruuvi-aqi';
export { moldIndex } from './moldIndex';
export { whoIndex } from './whoAirq-24h';
export const calculationMethods: CalculationMethod[] = [ruuviAQIndex, resetIndex, eaqIndex, whoIndex, moldIndex];
