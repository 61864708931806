
import { AirQualityData, AirQualityResult, CalculationMethod } from './types';

export const eaqIndex: CalculationMethod = {
  name: 'The European Air Quality Index',
  description: ``,
  source: '',
  citation: '',
  variableOverrides: {
    temperature: {
      enabled: false,
    },
    humidity: {
      enabled: false,
    },
    pm25: {
      gradient: [
        { value: 0, color: 'green' },
        { value: 10, color: 'lightgreen' },
        { value: 20, color: 'yellow' },
        { value: 25, color: 'orange' },
        { value: 50, color: 'orangered' },
        { value: 75, color: 'red' },
        { value: 800, color: 'crimson' },
      ],
    },
    voc: {
      enabled: false,
    },
    nox: {
      tooltip: 'NOx levels are measured by absolute value in standard',
      noDataByDefault: true,
    },
    co2: {
      enabled: false,
    },
    o3: {
      name: 'O3 (µg/m³)',
      key: 'o3',
      min: 0,
      max: 500,
      initialValue: 50,
      enabled: true,
      noDataByDefault: false,
      gradient: [
        { value: 0, color: 'green' },
        { value: 50, color: 'lightgreen' },
        { value: 100, color: 'yellow' },
        { value: 130, color: 'orange' },
        { value: 240, color: 'orangered' },
        { value: 380, color: 'red' },
        { value: 800, color: 'crimson' },
      ],
  },
  so2: {
      name: 'SO2 (µg/m³)',
      key: 'so2',
      min: 0,
      max: 500,
      initialValue: 50,
      enabled: true,
      noDataByDefault: false,
      gradient: [
        { value: 0, color: 'green' },
        { value: 100, color: 'lightgreen' },
        { value: 200, color: 'yellow' },
        { value: 350, color: 'orange' },
        { value: 500, color: 'orangered' },
        { value: 750, color: 'red' },
        { value: 1250, color: 'crimson' },
      ],
  },
    luminosity: { 
      enabled: false,
    },
    soundLevel: {
      enabled: false,
    },
  },
  calculate: (data: AirQualityData): AirQualityResult => {
    const AQG = Math.min(scorePpm(data.pm25), scoreNox(data.nox), scoreSo(data.so2), scoreO3(data.o3));

    return {
      index: AQG,
      result: getResultFromAQG(AQG),
      color: getColorFromAQG(AQG),
      description: getDescriptionFromAQG(AQG),
      };
  }
}
  
function scorePpm(ppm: number | undefined): number {
  if (ppm === undefined) return 5;
  if (ppm > 75) return 0;
  if (ppm > 50) return 1;
  if (ppm > 25) return 2;
  if (ppm > 20) return 3;
  if (ppm > 15) return 4;
  return 5;
}

function scoreO3(o3: number | undefined): number {
  if (o3 === undefined) return 5;
  if (o3 > 380) return 0;
  if (o3 > 240) return 1;
  if (o3 > 130) return 2;
  if (o3 > 100) return 3;
  if (o3 > 50) return 4;
  return 5;
}

function scoreNox(nox: number | undefined): number {
  if (nox === undefined) return 5;
  if (nox > 340) return 0;
  if (nox > 230) return 1;
  if (nox > 120) return 2;
  if (nox > 90) return 3;
  if (nox > 40) return 4;
  return 5;
}

function scoreSo(so2: number | undefined): number {
  if (so2 === undefined) return 5;
  if (so2 > 750) return 0;
  if (so2 > 500) return 1;
  if (so2 > 350) return 2;
  if (so2 > 200) return 3;
  if (so2 > 100) return 4;
  return 5;
}


function getColorFromAQG(AQG: number): string {
  if (AQG === 0) return 'red';
  if (AQG === 1) return 'orangered';
  if (AQG === 2) return 'orange';
  if (AQG === 3) return 'khaki';
  if (AQG === 4) return 'lightgreen';
  if (AQG === 5) return 'green';
  return 'black';
}

function getDescriptionFromAQG(AQG: number): string {
  if (AQG === 0) return 'Reduce physical activities outdoors.';
  if (AQG === 1) return 'Consider reducing intense activities outdoors, if you experience symptoms such as sore eyes, a cough or sore throat';
  if (AQG === 2) return 'Consider reducing intense activities outdoors, if you experience symptoms such as sore eyes, a cough or sore throat';
  if (AQG === 3) return 'Enjoy your usual outdoor activities';
  if (AQG === 4) return 'Enjoy your usual outdoor activities';
  if (AQG === 5) return 'The air quality is good. Enjoy your usual outdoor activities.';
  return 'No data';
}

function getResultFromAQG(AQG: number): string {
  if (AQG === 0) return 'Extremely poor ';
  if (AQG === 1) return 'Very poor';
  if (AQG === 2) return 'Poor';
  if (AQG === 3) return 'Moderate';
  if (AQG === 4) return 'Fair';
  if (AQG === 5) return 'Good';
  return 'No data';
}